import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import RegenemmAppIcon from "../images/regenemm-app-icon.png"
import RegenemmCheckCircle from "../images/check-circle.svg"
import NetworkIconR from "../images/chart-network-purple.svg"
import BrainIconR from "../images/brain-purple.svg"
import BrowserIconR from "../images/browser-purple.svg"
import FingerprintIconR from "../images/fingerprint-purple.svg"
import ListIconR from "../images/list-ul-purple.svg"
import ChartIconR from "../images/chart-line-purple.svg"

const icons = [
  { image: FingerprintIconR, alt: "an icon showing a chart network" },
  { image: BrainIconR, alt: "an icon showing a chart network" },
  { image: BrowserIconR, alt: "an icon showing a chart network" },
  { image: ListIconR, alt: "an icon showing a chart network" },
  { image: ChartIconR, alt: "an icon showing a chart network" },
  { image: NetworkIconR, alt: "an icon showing a chart network" },
]

export default ({ data }) => {
  const {
    page: {
      childMarkdownRemark: { frontmatter },
    },
    people,
    screens,
  } = data

  return (
    <Layout meta={frontmatter.meta}>
      <section className="regenemm-banner">
        <BackgroundImage
          className="regenemm-bg"
          id="regenemm-bg-corner"
          fluid={frontmatter.backgroundImage.childImageSharp.fluid}
          style={{
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#000",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="grid-2-col">
                <div className="regenemm-banner-content flex flex-ac">
                  <div>
                    <h1 className="animated fadeInUp fast">
                      {frontmatter.title}
                    </h1>
                    <p className="lead animated fadeInUp">
                      {frontmatter.subtitle}
                    </p>
                    <Link
                      to={`/expression-of-interest`}
                      className="btn btn-sm btn-purple-solid"
                    >
                      Participate in Pilot Study
                    </Link>
                  </div>
                </div>
                <div className="app-img-wrapper">
                  <div className="app-img">
                    <Img
                      className="img-responsive"
                      fluid={frontmatter.image.childImageSharp.fluid}
                      alt={frontmatter.imageDescription}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </section>

      <section className="regenemm-section app-icon-section">
        <div className="container">
          <div className="row flex flex-jc">
            <div className="app-icon-content">
              <img
                src={RegenemmAppIcon}
                className=""
                alt="The Regenemm® app icon"
                aria-hidden="true"
              />
              <h2>
                The Regenemm® app helps you navigate life’s competing demands
              </h2>
              <p className="lead">
                Regenemm® is designed to improve human performance and help you
                perform at optimal stress levels.
              </p>
              <Link
                to={`/expression-of-interest`}
                className="btn btn-purple-solid"
              >
                Participate in Pilot Study
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="regenemm-section">
        <div className="container">
          <div className="row">
            <div className="grid-2-col">
              <div>
                <h3>Regenemm® is made for you</h3>
                <p className="lead">
                  Our product is different, led by our CEO Dr Brendan O’Brien
                  Consultant Neurosurgeon, this platform is carefully designed
                  by our team of dedicated medical advisors.
                </p>
                <p className="lead">
                  Further strengthened by organisational and research
                  psychologists, data scientists and machine learning engineers.
                </p>
                <p className="lead">
                  Our strength, is we use evidenced based medical decision
                  making throughout the entire platform. Following world best
                  guidelines for data security and protection.
                </p>
                <p className="lead">Regenemm® has been made for:</p>
                <div className="flex mb-1">
                  <img
                    src={RegenemmCheckCircle}
                    className="mr-1"
                    alt="check icon"
                    aria-hidden="true"
                  />
                  <span className="lead">
                    Medical & Allied health professionals
                  </span>
                </div>
                <div className="flex">
                  <img
                    src={RegenemmCheckCircle}
                    className="mr-1"
                    alt="check icon"
                    aria-hidden="true"
                  />
                  <span className="lead">
                    Skilled professionals & corporate working groups
                  </span>
                </div>
              </div>

              <div className="flex flex-jc">
                <div className="people-img mt-2">
                  <Img
                    className="img-responsive"
                    fluid={people.childImageSharp.fluid}
                    alt="Professional people"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row diff-row">
            <div className="grid-2-col">
              <div className="screen-img">
                <Img
                  className="img-responsive"
                  fluid={screens.childImageSharp.fluid}
                  alt="Screen grabs from the Regenemm® app showing a stress performance curve, a heart rate variability reading and psychometric scores"
                />
              </div>

              <div className="diff-content">
                <h3>Regenemm® is different</h3>
                <p className="lead">
                  Regenemm® is not like other health, wellbeing or mindfulness
                  apps. The platform provides:
                </p>
                <div className="flex mb-1">
                  <img
                    src={RegenemmCheckCircle}
                    className="mr-1"
                    alt="check icon"
                    aria-hidden="true"
                  />
                  <span className="lead">Medical based decisions making</span>
                </div>
                <div className="flex mb-1">
                  <img
                    src={RegenemmCheckCircle}
                    className="mr-1"
                    alt="check icon"
                    aria-hidden="true"
                  />
                  <span className="lead">
                    Decision making based on a greater understanding of you
                  </span>
                </div>
                <div className="flex mb-1">
                  <img
                    src={RegenemmCheckCircle}
                    className="mr-1"
                    alt="check icon"
                    aria-hidden="true"
                  />
                  <span className="lead">Better holistic insights</span>
                </div>
                <div className="flex mb-1">
                  <img
                    src={RegenemmCheckCircle}
                    className="mr-1"
                    alt="check icon"
                    aria-hidden="true"
                  />
                  <span className="lead">
                    Data control. You’re in full control of what you share
                  </span>
                </div>
                <div className="flex">
                  <img
                    src={RegenemmCheckCircle}
                    className="mr-1"
                    alt="check icon"
                    aria-hidden="true"
                  />
                  <span className="lead">Anonymised data</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="regenemm-section">
        <div className="container">
          <div className="row">
            <div className="flex flex-jc">
              <div className="regenemm-features">
                <h4>{frontmatter.contentHeading}</h4>
                <p className="lead">{frontmatter.content}</p>
              </div>
            </div>
            <div className="grid-3-col">
              {frontmatter.contentItems.map((c, i) => (
                <div key={c.title} className="product-feature regenemm-feature">
                  <img
                    src={icons[i % icons.length].image}
                    alt={icons[i % icons.length].alt}
                    aria-hidden="true"
                  />
                  <h3>{c.title}</h3>
                  <p>{c.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query LandingPageQuery($name: String!) {
    page: file(
      relativeDirectory: { eq: "landing-pages" }
      name: { eq: $name }
    ) {
      childMarkdownRemark {
        frontmatter {
          title
          subtitle
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 332) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageDescription
          backgroundImage {
            childImageSharp {
              fluid(quality: 95, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          contentHeading
          content
          contentItems {
            title
            content
          }
          meta {
            title
            description
            image {
              ...Layout_metaImage
            }
          }
        }
      }
    }

    people: file(relativePath: { eq: "professional-people.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    screens: file(relativePath: { eq: "regenemm-screens.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 565) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    videobg: file(relativePath: { eq: "video-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
